import { 
  DashboardOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

const dashBoardNavTree = [{
  key: 'home',
  path: `${APP_PREFIX_PATH}/home`,
  title: 'home',
  icon: DashboardOutlined,
  breadcrumb: false,
  submenu: []
},{
  key: 'ocr',
  path: `${APP_PREFIX_PATH}/ocr`,
  title: 'สแกนข้อความ',
  icon: DashboardOutlined,
  breadcrumb: true,
  submenu: []
},{
  key: 'video-call',
  path: `${APP_PREFIX_PATH}/video-call`,
  title: 'video-call',
  icon: DashboardOutlined,
  breadcrumb: true,
  submenu: []
},]

const navigationConfig = [
  ...dashBoardNavTree
]

export default navigationConfig;
