import React from 'react'
// import AppBreadcrumb from 'components/layout-components/AppBreadcrumb';
import IntlMessage from '../util-components/IntlMessage';

export const PageHeader = ({ title, display }) => {
	return (
		display ? (
			<div className="app-header ant-layout-header app-page-header layout-top-nav-no">
				<h3 className="w-100-p mb-0 ml-3 mr-3 font-weight-semibold">
					<div className={`page-header-app`}>
					<IntlMessage id={title? title : 'home'}/>
					</div>
				</h3>
				{/* <AppBreadcrumb /> */}
			</div>
		)
		: null
	)
}

export default PageHeader